import { Button } from 'antd';
import type { PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { ImgFormatter } from 'egenie-utils';
import React from 'react';
import type { Store } from './store';
import type { GoodsDetailItem } from './types';

export function parcelListManageSkuDetail(context: Store) {
  return {
    tab: {
      name: '商品明细',
      value: 'detail',
    },
    grid: {
      columns: [
        {
          key: 'operation',
          name: '操作',
          width: 100,
          formatter: ({ row }) => {
            return (
              <Button
                onClick={() => context.uniqueCodeDetailStore.handleOpen(row)}
                type="link"
              >
                查看唯一码
              </Button>
            );
          },
        },
        {
          key: 'productName',
          name: '商品名称',
          width: 200,
        },
        {
          key: 'productNo',
          name: '商品编码',
          sortable: true,
          width: 200,
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          sortable: true,
          width: 200,
        },
        {
          key: 'pic',
          name: '图片',
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'spec',
          name: '规格',
          width: 200,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          key: 'sendNum',
          name: '送货数量',
          width: 100,
        },
        {
          key: 'receiveNum',
          name: '收货数量',
          width: 100,
        },
        {
          key: 'purchaseNo',
          name: '采购单号',
          width: 200,
        },
        {
          key: 'printStateDesc',
          name: '打印状态',
          width: 80,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'cloudPackageCodeDetailId',
      sortByLocal: false,
      onSelectSum: false,
      setColumnsDisplay: true,
      gridIdForColumnConfig: 'egenieCloudWmsParcelListManageIndexSkuDetail',
    },
    api: {
      onQuery: ({
        data,
        pid,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
          cond,
        } = data;
        return request<PaginationData<GoodsDetailItem>>({
          url: '/api/cloud/pms/package/code/detail/page',
          method: 'POST',
          data: {
            ...cond,
            sidx,
            sord,
            page,
            pageSize,
            cloudPackageCodeId: pid,
          },
        });
      },
    },
  };
}
