import { Programme, ExportStore } from 'egenie-utils';
import { observable } from 'mobx';
import React from 'react';
import { getOwner } from '../../utils';
import { mainSubStructureModel } from './mainSubStructureModel';
import { UniqueCodeDetailStore } from './uniqueCodeDetail';
import { ScanParcelCodeModalStore } from './scanParcelCodeModal';

export class Store {
  constructor() {
    this.init();
  }

  public uniqueCodeDetailStore: UniqueCodeDetailStore = new UniqueCodeDetailStore();

  public scanParcelCodeModalStore: ScanParcelCodeModalStore = new ScanParcelCodeModalStore();

  public exportStore: ExportStore = new ExportStore({ parent: null });

  private init = () => {
    getOwner()
      .then((data) => {
        this.programme.filterItems.addDict({ ownerIds: data });
      });
  };

  @observable public programme: Programme = new Programme({
    gridModel: mainSubStructureModel(this),
    filterItems: [
      {
        type: 'date',
        field: 'date',
        label: '日期类型',
        selectValue: '1',
        data: [
          {
            value: '1',
            label: '创建时间',
          },
        ],
      },
      {
        type: 'input',
        field: 'packageCodeNo',
        label: '包裹码编码',
      },
      {
        type: 'input',
        field: 'skuNo',
        label: 'sku编码',
      },
      {
        type: 'input',
        field: 'purchaseOrderNo',
        label: '采购单号',
      },
      {
        type: 'select',
        field: 'ownerIds',
        label: '货主',
      },
    ],
    moduleName: 'egenieCloudWmsParcelListManageIndex',
  });
}
