import { Modal } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { MainSubStructure, MainSubStructureModel, TimeStampFormatter, request } from 'egenie-utils';
import { action, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import type { GoodsDetailItem, UnicodeDetailItem } from './types';

export class UniqueCodeDetailStore {
  @observable.ref public goodsDetailItem: GoodsDetailItem = {};

  @observable public visible = false;

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'skuNo',
          name: 'sku编码',
          width: 200,
        },
        {
          key: 'uniqueCode',
          name: '唯一码',
          width: 200,
        },
        {
          key: 'createTime',
          name: '创建时间',
          formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'uniqueCode',
      showCheckBox: false,
      showReset: false,
      showSelectedTotal: false,
    },
    api: {
      onQuery: (params) => {
        const {
          filterParams,
          ...rest
        } = params;
        return request<PaginationData<UnicodeDetailItem>>({
          method: 'POST',
          url: '/api/cloud/pms/package/code/detail/unique/code/page',
          data: {
            ...rest,
            cloudPackageCodeId: this.goodsDetailItem.cloudPackageCodeId,
            cloudPackageCodeDetailId: this.goodsDetailItem.cloudPackageCodeDetailId,
          },
        });
      },
    },
    hiddenSubTable: true,
  });

  @action public handleCancel = () => {
    this.visible = false;
    this.goodsDetailItem = {};
    this.mainSubStructureModel.gridModel.resetAll();
  };

  @action public handleOpen = (purchaseSkuDetailItem: GoodsDetailItem) => {
    this.visible = true;
    this.goodsDetailItem = toJS(purchaseSkuDetailItem);
    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.onQuery();
  };
}

@observer
export class UniqueCodeDetail extends React.Component<{ uniqueCodeDetailStore: UniqueCodeDetailStore; }> {
  render() {
    const {
      uniqueCodeDetailStore: {
        handleCancel,
        visible,
        mainSubStructureModel,
      },
    } = this.props;
    return (
      <Modal
        centered
        footer={null}
        onCancel={handleCancel}
        title="唯一码详情"
        visible={visible}
        width={900}
      >
        <div style={{ height: 400 }}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
      </Modal>
    );
  }
}
