import { message, Modal, Input, Button, InputNumber } from 'antd';
import { formatNumber, printHelper } from 'egenie-common';
import type { BaseData } from 'egenie-utils';
import { ImgFormatter, MainSubStructure, MainSubStructureModel, request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { Observer, observer } from 'mobx-react';
import React from 'react';
import type { GoodsDetailItem } from './types';

export class ScanParcelCodeModalStore {
  @observable public visible = false;

  @action public handlePrintUnicode = (data: GoodsDetailItem[]) => {
    const newData = data.map((item) => ({
      cloudPackageCodeDetailId: item.cloudPackageCodeDetailId,
      printNum: item.printNum >>> 0,
    }))
      .filter((item) => item.printNum > 0);

    if (newData.length <= 0) {
      const error = '请输入打印数量';
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    Modal.confirm({
      title: '确定打印吗?',
      onOk: async() => {
        const printData = await request<BaseData<{ userDataList: any[]; tempData: any; }>>({
          url: '/api/cloud/pms/package/code/unique/code/receive/print',
          method: 'POST',
          data: newData,
        });

        printHelper.toggleToLodop();
        await printHelper.print({
          preview: true,
          templateData: printData?.data?.tempData,
          contents: printData?.data?.userDataList,
        });

        message.success('打印成功');
      },
    });
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          width: 150,
          key: 'operate',
          name: '操作',
          frozen: true,
          formatter: ({ row }: { row: GoodsDetailItem; }) => (
            <Button
              onClick={() => this.handlePrintUnicode([row])}
              size="small"
              type="link"
            >
              打印唯一码
            </Button>
          ),
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'productNo',
          name: '商品编码',
          width: 200,
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
        },
        {
          key: 'color',
          name: '颜色',
          width: 80,
        },
        {
          key: 'size',
          name: '尺码',
          width: 80,
        },
        {
          key: 'sendNum',
          name: '送货数量',
          width: 80,
        },
        {
          key: 'printNum',
          name: '打印数量',
          width: 120,
          formatter: ({ rowIdx }) => {
            return (
              <Observer>
                {
                  () => (
                    <InputNumber
                      max={99999}
                      min={0}
                      onChange={(value) => this.mainSubStructureModel.gridModel.rows[rowIdx].printNum = formatNumber(value)}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].printNum}
                    />
                  )
                }
              </Observer>
            );
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'uniqueCode',
      showCheckBox: false,
      showReset: false,
      showSelectedTotal: false,
      showPager: false,
    },
    api: { onQuery: () => Promise.resolve() },
    hiddenSubTable: true,
  });

  @action public handleCancel = () => {
    this.visible = false;
    this.parcelCode = '';
    this.mainSubStructureModel.gridModel.resetAll();
  };

  @action public handleOpen = () => {
    this.visible = true;
    this.parcelCode = '';
    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.onQuery();
  };

  @observable public parcelCode = '';

  @observable public handleParcelCodeChange = (event) => {
    this.parcelCode = event.target.value;
  };

  @action public handlePressEnter = () => {
    const parcelCode = this.parcelCode;
    this.parcelCode = '';
    if (!parcelCode) {
      const error = '请扫描包裹码';
      message.error({
        key: error,
        content: error,
      });
      return;
    }

    request<BaseData<GoodsDetailItem[]>>({
      url: '/api/cloud/pms/package/code/scan',
      method: 'post',
      data: { cloudPackageCode: parcelCode },
    })
      .then((info) => {
        this.mainSubStructureModel.gridModel.resetAll();
        this.mainSubStructureModel.gridModel.rows = info?.data || [];
      });
  };
}

@observer
export class ScanParcelCodeModal extends React.Component<{ scanParcelCodeModalStore: ScanParcelCodeModalStore; }> {
  render() {
    const {
      scanParcelCodeModalStore: {
        handleCancel,
        visible,
        mainSubStructureModel,
        parcelCode,
        handleParcelCodeChange,
        handlePressEnter,
        handlePrintUnicode,
      },
    } = this.props;
    return (
      <Modal
        centered
        destroyOnClose
        maskClosable={false}
        okButtonProps={{ disabled: mainSubStructureModel.gridModel.rows.length <= 0 }}
        okText="打印全部唯一码"
        onCancel={handleCancel}
        onOk={() => handlePrintUnicode(mainSubStructureModel.gridModel.rows)}
        title="收货"
        visible={visible}
        width={1100}
      >
        <Input
          autoFocus
          onChange={handleParcelCodeChange}
          onPressEnter={handlePressEnter}
          placeholder="请扫描包裹码"
          style={{ width: 300 }}
          value={parcelCode}
        />
        <div style={{ height: 400 }}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
      </Modal>
    );
  }
}
