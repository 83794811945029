import type { MainItem, GoodsDetailItem } from './types';
import { parcelListManageSkuDetail } from './parcelListManageSkuDetail';
import { logSubGrid } from '../../utils';
import type { Store } from './store';
import type { PaginationData } from 'egenie-utils';
import { MainSubStructureModel, request, TimeStampFormatter } from 'egenie-utils';
import React from 'react';
import { message, Modal } from 'antd';
import { printHelper } from 'egenie-common';
import type { BaseData } from 'egenie-common';

export function mainSubStructureModel(context: Store): MainSubStructureModel {
  return new MainSubStructureModel({
    buttons: [
      {
        text: '打印唯一码',
        permissionId: '2034',
        handleClick: () => {
          let selectMainRows: MainItem[] = context.programme.gridModel.gridModel.selectRows;
          let selectSubRows: GoodsDetailItem[] = [];
          let cloudPackageCodeDetailIds = '';

          if (context.programme.gridModel.subTablesModel.activeTab === 'detail') {
            selectSubRows = context.programme.gridModel.subTablesModel.listModel.find((item) => item.tab.value === 'detail').gridModel.selectRows || [];
            cloudPackageCodeDetailIds = selectSubRows.map((item) => item.cloudPackageCodeDetailId).join(',');
          }

          if (!((selectMainRows.length <= 1 && selectSubRows.length > 0) || (selectMainRows.length >= 1 && selectSubRows.length === 0))) {
            const err = '请勾选主表或子表数据进行打印';
            message.warn({
              key: err,
              content: err,
            });
            return;
          }

          Modal.confirm({
            title: '确定打印吗?',
            onOk: async() => {
              selectMainRows = selectSubRows.length === 0 ? selectMainRows : [context.programme.gridModel.gridModel.cursorRow];
              const cloudPackageCodeIds: string = selectMainRows.map((item) => item.cloudPackageCodeId).join(',');

              const printData = await request<BaseData<{ userDataList: any[]; tempData: any; }>>({
                url: '/api/cloud/pms/package/code/unique/code/print',
                method: 'POST',
                data: {
                  tempId: 0,
                  tempType: '27',
                  cloudPackageCodeIds,
                  cloudPackageCodeDetailIds,
                },
              });

              printHelper.toggleToLodop();
              await printHelper.print({
                preview: true,
                templateData: printData?.data?.tempData,
                contents: printData?.data?.userDataList,
              });

              message.success('打印成功');
              context.programme.gridModel.onRefresh();
              if (context.programme.gridModel.subTablesModel.activeTab === 'detail') {
                context.programme.gridModel.subTablesModel.listModel.find((item) => item.tab.value === 'detail')
                  .gridModel
                  .onRefresh();
              }
            },
          });
        },
      },
      {
        text: '导出',
        handleClick: () => {
          const ids = Array.from(context.programme.gridModel.gridModel.selectedIds)
            .join(',');
          const fileName = '包裹单';
          const exportType = 'cloud_package_code';
          const queryParam = context.programme.filterItems.params;
          const queryParamShow = context.programme.filterItems.translateParams.join(' ');

          if (ids) {
            context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
      {
        permissionId: '6017',
        text: '收货',
        handleClick: () => context.scanParcelCodeModalStore.handleOpen(),
      },
    ],
    pageId: '60197',
    grid: {
      columns: [
        {
          key: 'packageCodeNo',
          name: '包裹码编号',
          width: 200,
        },
        {
          key: 'receiveStateDesc',
          name: '收货状态',
          width: 80,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 150,
          sortable: true,
          formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
        },
        {
          key: 'ownerName',
          name: '货主',
          width: 150,
        },
        {
          key: 'sendTotalNum',
          name: '送货总数量',
          width: 100,
        },
        {
          key: 'receiveTotalNum',
          name: '收货总数量',
          width: 100,
        },
        {
          key: 'printCount',
          name: '打印次数',
          width: 100,
        },
        {
          key: 'printStateDesc',
          name: '打印状态',
          width: 100,
        },
        {
          key: 'vendorPrintDesc',
          name: '供应商贴码',
          width: 200,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'cloudPackageCodeId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      setColumnsDisplay: true,
      gridIdForColumnConfig: 'egenieCloudWmsParcelListManageIndex',
    },

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams,
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/pms/package/code/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    subTables: {
      activeTab: 'detail',
      tabsFlag: {
        inited: {
          detail: true,
          log: false,
        },
        searched: {},
      },
      list: [
        // @ts-ignore
        parcelListManageSkuDetail(context),
        logSubGrid(180000),
      ],
    },
  });
}
