import { ExportModal, ProgrammeComponent } from 'egenie-utils';
import React from 'react';
import { Store } from './store';
import { UniqueCodeDetail } from './uniqueCodeDetail';
import { ScanParcelCodeModal } from './scanParcelCodeModal';

export default function() {
  const store = React.useMemo(() => new Store(), []);
  const {
    programme,
    exportStore,
    uniqueCodeDetailStore,
    scanParcelCodeModalStore,
  } = store;

  return (
    <>
      <ProgrammeComponent store={programme}/>
      <ExportModal store={exportStore}/>
      <UniqueCodeDetail uniqueCodeDetailStore={uniqueCodeDetailStore}/>
      <ScanParcelCodeModal scanParcelCodeModalStore={scanParcelCodeModalStore}/>
    </>
  );
}
